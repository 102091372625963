<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Signing in from Digital Gateway</h1>
        <h2>{{ message }}</h2>
        <p>referrer: {{ referrer }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// delay function
function delay (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default {
  name: 'DigitalGateway',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.referrer = document.referrer
    await delay(4000)
    this.login()
  },
  data () {
    return {
      message: 'contacting server...',
      referrer: null
    }
  },
  methods: {
    login: async function () {
      try {
        const init = {
          headers: {
            'X-API-KEY': this.$route.params.id
          }
        }
        window.localStorage.setItem('frmCloudExApiKey', this.$route.params.id)
        let response = await this.$Amplify.API.get('cosmos', '/account', init)
        if (response.key === this.$route.params.id) {
          this.message = 'Logged in'
          const account = response
          if (account.acgroups) {
            account.acgroups = ['norole']
          }
          window.localStorage.setItem('frmCloudExAcgroups', JSON.stringify(account.acgroups))
          window.localStorage.setItem('frmCloudExApiKey', response.key)
          window.localStorage.setItem('frmCloudExUsername', account.organisation)
          const user = { acgroups: account.acgroups }
          this.$store.commit('setKey', response.key)
          this.$store.commit('setUsername', account.organisation)
          this.$store.commit('setUser', user)
          this.$stat.log({ page: 'digitalgateway', action: 'logged in' })
          if (account.homepage) {
            this.$router.replace({ name: account.homepage })
          } else {
            this.$router.replace({ name: 'Start' })
          }
        } else {
          this.message = 'Invalid credentials'
          this.signOutExternal()
        }
      } catch (e) {
        this.message = 'Invalid credentials'
        this.$logger.warn('getCategories loading error' + e)
        this.signOutExternal()
      }
    },
    signOutExternal: function () {
      window.localStorage.removeItem('frmCloudExApiKey')
      window.localStorage.removeItem('frmCloudExUsername')
      this.key = null
      this.username = null
      this.user = null
      this.account = false
      this.$store.commit('setKey', null)
      this.$store.commit('setUsername', null)
      this.$router.push({ name: 'Settings' })
    }
  }
}
</script>
